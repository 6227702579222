import React from 'react';

import KafkaAkamaiPlans from '../../contents/plans/kafka/akamai.json';
import KafkaHetznerPlans from '../../contents/plans/kafka/hetzner.json';
import TableView from '../TableView';
import { Plan } from './types';
import { getColumns, getPlansData } from './utils';

interface KafkaPlanProps {
  provider: string;
}

const Plans: Record<string, Plan[]> = {
  akamai: KafkaAkamaiPlans,
  hetzner: KafkaHetznerPlans,
};

const KafkaPlan = (props: KafkaPlanProps) => {
  const plans = Plans[props.provider];
  const columns = getColumns(plans[0]);
  return (
    <div className="mt-6 space-y-4">
      <h3 className="text-2xl text-foreground">Plan</h3>
      <div className="space-y-16 mt-16">
        <div className="mt-6 space-y-4">
          <h3 className="text-2xl text-foreground">Small Plan</h3>
          <p>
            The small plan offers a great starting point for small organizations
            or hobby developers to develop products with Kafka.
          </p>
          <TableView
            columns={columns}
            data={getPlansData(plans.filter((p) => p.group === 'Small'))}
          />
        </div>
        <div className="mt-6 space-y-4">
          <h3 className="text-2xl text-foreground">Medium Plan</h3>
          <p>
            The medium plan offers fault tolerance and high availability for
            production workloads.
          </p>
          <TableView
            columns={columns}
            data={getPlansData(plans.filter((p) => p.group === 'Medium'))}
          />
        </div>
        <div className="mt-6 space-y-4">
          <h3 className="text-2xl text-foreground">Large Plan</h3>
          <p>
            The large plan offers fault tolerance and high availability for
            enterprise grade workloads.
          </p>
          <TableView
            columns={columns}
            data={getPlansData(plans.filter((p) => p.group === 'Large'))}
          />
        </div>
      </div>
    </div>
  );
};

export default KafkaPlan;
