import React from 'react';
import CTA from '../CTA';
import { Link } from 'gatsby';

export interface ContentItem {
  url: string;
  title: string;
}

export interface TableOfContentsProps {
  contents: ContentItem[];
}

const TableOfContents = (props: TableOfContentsProps) => {
  return (
    <div>
      <h3 className="text-foreground font-bold">Table of Contents</h3>
      <ul className="space-y-2 mt-4">
        {props.contents.map((item) => (
          <li className="hover:text-foreground">
            <Link to={item.url}>{item.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TableOfContents;
