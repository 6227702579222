import React from 'react';

import VectorDBAkamaiPlans from '../../contents/plans/vectordb/akamai.json';
import VectorDBHetznerPlans from '../../contents/plans/vectordb/hetzner.json';
import TableView from '../TableView';
import { Plan } from './types';
import { getColumns, getPlansData } from './utils';

interface VectorDBPlanProps {
  provider: string;
}

const Plans: Record<string, Plan[]> = {
  akamai: VectorDBAkamaiPlans,
  hetzner: VectorDBHetznerPlans,
};

const VectorDBPlan = (props: VectorDBPlanProps) => {
  const plans = Plans[props.provider];

  return (
    <div className="mt-6 space-y-4">
      <h3 className="text-2xl text-foreground">Plan</h3>
      <p>Bitnimbus VectorDB plans offers you different plans for your needs.</p>
      <TableView columns={getColumns(plans[0])} data={getPlansData(plans)} />
    </div>
  );
};

export default VectorDBPlan;
