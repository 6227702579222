import React, { useRef, useState } from 'react';

import { useActivePath } from '../../hooks';
import KafkaLanding from './kafka';
import VectorDBLanding from './vectordb';
import AnimatedArrow from '../AnimatedArrow';
import Carousel, { SlideDirection } from '../Carousel';

const ProductLanding = () => {
  const ref = useRef<HTMLElement>(null);
  const [translate, setTranslate] = useState(SlideDirection.TO_LEFT);
  useActivePath('/#products', ref);

  const handleSlide = (direction: string) => {
    setTranslate(
      direction === 'left' ? SlideDirection.TO_LEFT : SlideDirection.TO_RIGHT
    );
  };

  return (
    <section className="section scroll-mt-16" id="products" ref={ref}>
      <Carousel
        slides={[
          <div className="w-[100%] lg:flex">
            <VectorDBLanding onSlideChange={handleSlide} />
            <div
              className={`self-center lg:ml-4 lg:mt-0 mt-8 flex justify-center ${translate === SlideDirection.TO_RIGHT ? 'opacity-0' : 'opacity-100'}`}
            >
              <AnimatedArrow
                direction="right"
                onClick={() => handleSlide('right')}
              />
            </div>
          </div>,
          <div className="w-[100%] lg:flex lg:flex-row-reverse">
            <KafkaLanding onSlideChange={handleSlide} />
            <div
              className={`self-center lg:mr-4 lg:mt-0 mt-8 flex justify-center ${translate === SlideDirection.TO_LEFT ? 'opacity-0' : 'opacity-100'}`}
            >
              <AnimatedArrow
                direction="left"
                onClick={() => handleSlide('left')}
              />
            </div>
          </div>,
        ]}
        moveTo={translate}
      />
    </section>
  );
};

export default ProductLanding;
