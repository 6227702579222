import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import { graphql, useStaticQuery } from 'gatsby';

const allResourcesQuery = graphql`
  query {
    allMdx {
      edges {
        node {
          excerpt
          frontmatter {
            slug
            title
          }
        }
      }
    }
  }
`;

const Resources = () => {
  const data = useStaticQuery(allResourcesQuery);

  return (
    <Layout>
      <section className="my-6 section h-[100%]">
        <div>
          <h1 className="text-center">Resources</h1>
        </div>
        <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8 my-6 justify-center">
          {data.allMdx.edges.map((edge: any) => (
            <div className="card" key={edge.node.slug}>
              <h3 className="font-bold text-foreground text-xl">
                {edge.node.frontmatter.title}
              </h3>
              <p>
                {edge.node.excerpt}
                <span>
                  <Link
                    to={`/${edge.node.frontmatter.slug}`}
                    className="text-foreground underline underline-offset-2"
                  >
                    Read More &gt;
                  </Link>
                </span>
              </p>
            </div>
          ))}
          <div className="card">
            <h3 className="text-foreground font-bold text-xl">
              Bitnimbus.io Docs
            </h3>
            <div className="flex flex-col justify-between h-[100%] pb-8">
              <p>
                We have a documentation site that contains all the contents
                related to our products.
              </p>
              <a
                href="https://docs.bitnimbus.io"
                target="_blank"
                className="text-foreground underline underline-offset-2 text-right"
              >
                Explore &gt;
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Resources;
