import React from 'react';
import ArrowIcon from '../../icons/arrow.svg';

interface AnimatedArrowProps {
  direction?: 'left' | 'right';
  onClick?: () => void;
}

const AnimatedArrow = (props: AnimatedArrowProps) => {
  const direction = props.direction || 'left';
  const directionMap = {
    left: '',
    right: 'rotate-180',
  };
  const delayTimers = {
    right: ['0ms', '600ms', '1200ms'],
    left: ['1200ms', '600ms', '0ms'],
  };

  const opacity = {
    left: ['opacity-10', 'opacity-25', 'opacity-50'],
    right: ['opacity-50', 'opacity-25', 'opacity-10'],
  };

  return (
    <button
      className="flex lg:*:animate-none lg:*:hover:animate-fade-in-out *:animate-fade-in-out"
      onClick={props.onClick}
    >
      <ArrowIcon
        className={`${props.direction === 'left' ? '' : 'rotate-180'} h-[1rem] translate-x-[0.7rem] md:h-[1.2rem] ${opacity[direction][0]} text-foreground ${delayTimers[direction][0]}`}
        style={{ animationDelay: delayTimers[direction][0] }}
      />
      <ArrowIcon
        className={`${props.direction === 'left' ? '' : 'rotate-180'} h-[1rem] md:h-[1.2rem] translate-x-[0.35rem] ${opacity[direction][1]} text-foreground ${delayTimers[direction][1]}`}
        style={{ animationDelay: delayTimers[direction][1] }}
      />
      <ArrowIcon
        className={`${props.direction === 'left' ? '' : 'rotate-180'}  h-[1rem] ${direction === 'left' ? '' : ''} ${opacity[direction][2]} md:h-[1.2rem] text-foreground ${delayTimers[direction][2]}`}
        style={{ animationDelay: delayTimers[direction][2] }}
      />
    </button>
  );
};

export default AnimatedArrow;
