import { Plan, Spec } from './types';

export const getColumns = (plan: Plan) => {
  const columns = [
    {
      key: 'label',
      name: 'Plans',
    },
    {
      key: 'price_hourly',
      name: 'Hourly',
    },
    {
      key: 'price_monthly',
      name: 'Monthly',
    },
    ...plan.specs.map((spec: Spec) => ({ name: spec.label, key: spec.key })),
  ];
  return columns;
};

export const getPlansData = (plans: any) => {
  return plans.map((plan: any) => {
    const specs = plan.specs.reduce(
      (res: Record<string, string | number>, spec: Spec) => {
        res[spec.key] = spec.value;
        return res;
      },
      {}
    );
    return {
      label: plan.label,
      price_hourly: plan.price.hourly,
      price_monthly: plan.price.monthly,
      ...specs,
    };
  });
};
