import React from 'react';
import Layout from '../../components/Layout';
import Container from '../../components/Container';
import { graphql, useStaticQuery } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import Markdown from 'markdown-to-jsx';
import Seo from '../../components/Seo';
import TableOfContents from '../../components/TableOfContents';

const WhyBitnimbus = ({ data }: any) => {
  const getHeadingId = (children: string) => {
    const tokens = children.split(' ');
    const ALLOWED_SPECIAL_CHARACTERS = ['_', '-'];
    const isAllowedCharacter = (ch: string) =>
      (ch >= 'a' && ch <= 'z') ||
      (ch >= '0' && ch <= '9') ||
      ALLOWED_SPECIAL_CHARACTERS.includes(ch);
    const result = tokens.map((token: string) => {
      let alphaNumericToken = '';
      for (let i = 0; i < token.length; i++) {
        const ch = token.charAt(i);
        if (!isAllowedCharacter(ch)) {
          continue;
        }
        alphaNumericToken += ch;
      }
      return alphaNumericToken;
    });
    return result.join('-');
  };
  return (
    <Layout>
      <section className="mb-6 mx-8">
        <Container>
          <div>
            <div className="p-4 md:p-12">
              <div className="space-y-6">
                <h1 className="text-center text-4xl">
                  {data.mdx.frontmatter.title}
                </h1>
                {data.mdx.frontmatter.caption && (
                  <p className="px-4 md:px-12">
                    {data.mdx.frontmatter.caption}
                  </p>
                )}
              </div>
            </div>
            <div className="lg:grid lg:grid-cols-[30%_70%]">
              <div>
                <div className="lg:sticky lg:top-16 lg:m-0 lg:mr-6 border border-gray rounded-lg p-6 max-w-fit mx-auto my-4">
                  <TableOfContents contents={data.mdx.tableOfContents.items} />
                </div>
              </div>
              <div className="markdown">
                <ReactMarkdown
                  components={{
                    h2({ children }) {
                      return (
                        <h2
                          id={
                            typeof children === 'string'
                              ? getHeadingId(children.toLowerCase())
                              : children?.toString()
                          }
                          className="text-[1rem] leading-relaxed md:text-[1.75rem] md:leading-normal font-bold text-foreground"
                        >
                          {children}
                        </h2>
                      );
                    },
                  }}
                >
                  {data.mdx.body}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  );
};

export const Head = ({ data }: any) => {
  return (
    <Seo
      title={`${data.mdx.frontmatter.head} - Bitnimbus.io`}
      canonical="https://bitnimbus.io/why-bitnimbus"
    />
  );
};

export const query = graphql`
  query ($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      id
      tableOfContents
      body
      frontmatter {
        title
        slug
        caption
        head
      }
    }
  }
`;

export default WhyBitnimbus;
