import React, { useState } from 'react';

export enum SlideDirection {
  TO_RIGHT,
  TO_LEFT,
}

export interface CarouselProps {
  slides: React.ReactNode[];
  moveTo: SlideDirection;
}

const Carousel = (props: CarouselProps) => {
  return (
    <div className=" overflow-x-hidden max-w-[100%]">
      <div
        className={`flex w-[200%] ${props.moveTo === SlideDirection.TO_LEFT ? 'translate-x-0' : 'translate-x-[-49%]'} flex-shrink-0 transition-all duration-500 ease-in-out`}
      >
        {props.slides.map((slide) => slide)}
      </div>
    </div>
  );
};

export default Carousel;
