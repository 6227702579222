import React from 'react';

import Container from '../Container';

interface Column {
  key: string;
  name: string;
}

interface TableViewProps {
  columns: Column[];
  data: { [key: string]: string }[];
}

const TableView = (props: TableViewProps) => {
  return (
    <div>
      <Container className="card">
        <div className="overflow-auto">
          <table className="w-[100%] border-spacing-4">
            <thead className="w-[100%]">
              <tr className="*:border-b *:border-gray">
                {props.columns.map((col) => (
                  <th
                    className="p-2 text-foreground text-center first:text-left font-semibold"
                    key={col.key}
                  >
                    {col.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {props.data.map((d) => (
                <tr key={d.label} className="*:py-2">
                  {props.columns.map((col, index) => (
                    <td
                      key={`${d.label}-${index}`}
                      className="text-center first:text-left"
                    >
                      {d[col.key]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Container>
    </div>
  );
};

export default TableView;
